@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import "variables";

#root {
  height: 100vh;
  width: 100vw;
  overflow:hidden;
}

:fullscreen, ::backdrop {
  background-color: #f8f8f8;
} // process canvas full screen black 화면 버그 수정

body, ul, ol {
  margin: 0;
  padding: 0;
}

ul, ol, dl {
  list-style: none;
}

body, input, textarea, button, select {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// 코드 표현
code {
  font-family: Consolas, monospace;
  white-space: pre;
}

// 얇은 스크롤 바
.thin-scrollbar {
  overflow: auto;

  &.dark {
    &::-webkit-scrollbar {
      background-color: #555;
    }

    &::-webkit-scrollbar-thumb {
      background: #000;
    }
  }

  &.md {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
  }
  &::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0.08);
    width: 6px;
    height: 6px;

  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
  }
}

input, textarea, button, select {
  outline: none;
}

//iframe#webpack-dev-server-client-overlay{display:none!important}
