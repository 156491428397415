
.react-flow{
    .react-flow__node{
      &:has(.frozen) {
        pointer-events: none !important;
      }
    }

  &.zoomIn{
    .react-flow__pane  {
      cursor:zoom-in !important;
    }
    .react-flow__node{
      pointer-events: none !important;
      *{
        pointer-events: none;
      }
    }
  }
  &.zoomOut{
    .react-flow__pane  {
      cursor:zoom-out !important;
    }
    .react-flow__node{
      pointer-events: none !important;
      *{
        cursor:zoom-in !important;
      }
    }
  }
  &.pan{
    .react-flow__node{
      pointer-events: none !important;
    }
  }
}

.react-flow__pane  {

  &.selection{
    cursor: default;

  }
  &.dragging {
    cursor: grabbing;
  }
}

.react-flow__node {


  &:active {
    cursor: grabbing;
  }

  .three-menu {
    color: white;
    background: #292d39;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    border-radius: 4px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;

      &__label {
        padding-right: 8px;
        white-space: nowrap;
      }

      .tree-select {
        border: 1px solid hsl(0deg 0% 100%);

        .tree-select-base {
          input {
            color: #fff;
          }

          .drop-button {
            svg {
              color: #fff;
            }
          }
        }

        .three-drop-base {
          .child-list {
            color: black;
          }
        }
      }

      //color: white;
    }
  }

  .tree-select {
    width: 80%;
    position: relative;
    display: flex;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    padding: 2px 0;

    //background-color: hsl(0,0%,100%);
    &.focused {
      outline: 2px solid rgb(0, 138, 224);
      outline-offset: -2px;
    }

    .tree-select-base {
      width: 100%;
      height: 30px;
      background: none;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 2px 3px 2px 2px;

      input {
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        padding-left: 5px;
        font-size: 12px;
        background: none;

        &::placeholder {
          font-size: 10px;
          font-style: italic;
          color: rgba(0, 0, 0, 0.5);
        }

        &:disabled {
          color: rgb(170, 170, 170);
        }

      }

      .drop-button {
        width: 32px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
          background: rgba(52, 73, 94, 0.085);
        }

        &.disabled {
          color: rgb(170, 170, 170);
          background: rgba(0, 0, 0, 0);
          cursor: default;

          &:hover {
            background: rgba(0, 0, 0, 0);
          }
        }
      }
    }

    .three-drop-base {
      position: absolute;
      top: 40px;
      width: 100%;
      min-width: 100%;
      min-height: 40px;
      max-height: 278px;
      overflow-y: auto;
      overflow-x: auto;
      pointer-events: auto;
      cursor: auto;
      padding: 4px;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
      z-index: 200;
      float: left;

    }

    .node {
      display: block;

      .nodeHeader {
        width: 100%;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__icon {
          width: 15px;
          height: 15px;
          border: 1px solid #718093;
          color: #718093;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        &__title {
          margin-left: 10px;
          font-size: 12px;
          font-weight: 600;
        }
      }

      .nodeBody {
        width: auto;
        right: 0;
        top: 0;
        border-left: 0.5px dashed rgba(0, 0, 0, 0.4);
        padding: 0 0 5px 25px;
        display: inline-block;
        height: auto;
        position: relative;
        margin-left: 8px;

      }
    }

    .child-list {
      list-style: none;
      padding: 3px 0 3px 5px;
      border-radius: 5px;
      white-space: nowrap;
      cursor: pointer;
      position: relative;
      font-size: 12px;
      height: auto;

      &::before {
        position: absolute;
        width: 20px;
        height: 2px;
        left: -25px;
        top: 13px;
        content: "";
        border-bottom: 0.25px dashed rgba(0, 0, 0, 0.4);
      }

      &.isLast {
        &:hover {
          background: #f2f4f8;
        }
      }
    }

    .preView {
      position: absolute;
      top: 40px;
      width: 280px;
      height: auto;
      margin-left: 4px;
      min-height: 40px;
      overflow-y: auto;
      padding: 8px;
      border-radius: 4px;
      background: #fff;
      opacity: 0.9;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
      z-index: 200;
      font-weight: 500;

      &__header {
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: 700;
        //color: white;
      }

      &__partition {
        padding-left: 8px;

        &__header {
          display: flex;
          font-size: 14px;
          font-weight: 700;
          //color: #E3E0D2;
        }

        &__body {
          padding-left: 8px;
          font-size: 12px;
          display: flex;
          flex-direction: column;

          .infoColor {
            color: rgb(72, 197, 253);
          }

          .unitColor {
            color: rgb(72, 197, 253);
          }
        }
      }

      .historyChart {
        margin-top: 20px;
        height: 100px;
        border-radius: 4px;
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02);
        position: relative;
      }
    }

    svg {
      width: 15px;
      height: 15px;
      transition: all 0.5s ease;
    }
  }

  //.sticky-note-toolbar.text-size {
  //  background-color: var(--main-bg-color);
  //}

/*  .sticky-note-widget {
    --sticky-note-bg-color: #FFF7D1;
    position: relative;
    overflow: hidden;
    height: calc(100%);
    border-radius: 4px;
    background:var(--sticky-note-bg-color);
    min-width: 100px;
    cursor:default;

    .rdw-editor-toolbar, .rdw-option-wrapper{
      background:var(--sticky-note-bg-color);
    }

    .widget-header {
      background:var(--sticky-note-bg-color);
      border-bottom: 1px dashed #e7ddb4;
    }

    .demo-editor{
      max-height:calc(100% - 44px);
      background-color: var( --sticky-note-bg-color);

      &.hide-toolbar{
        max-height:calc(100%);
      }
      .DraftEditor-root{
        height:100%;
      }
    }
    .rdw-editor-toolbar{
      position:absolute;
      bottom:0;
      margin-bottom: 0;
      width:100%;
      padding:4px;
      height:36px;
      z-index: 100;

      .toolbar-font-size {
        width: 50px;
        background-color: var(--sticky-note-bg-color);
        border: 1px solid #ccc9b2;
      }
    }
    .rdw-editor-wrapper{
      height:100%;
      overflow-y:scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .rdw-option-wrapper{
      border:none;
    }
    .demo-editor{
      padding:20px 20px 10px 20px;
      &::-webkit-scrollbar {
        width: 10px;
        background-color: var( --sticky-note-bg-color);
        height: calc((100% - 25px));
      }

      &::-webkit-scrollbar-thumb {
        background: #7A7A7A;
        cursor: pointer !important;
        border-radius: 12px 12px 12px 12px;
      }
    }
    .rdw-image-wrapper{
      position: static;
      .rdw-image-modal{
        width:300px;
        height:240px;
        top:calc(-260px - 50%);
        left:calc(-165px + 50%);
        .rdw-image-modal-size{
          display:none;
        }
        .rdw-image-modal-upload-option{
          height:100px;
        }
      }
    }
    .rdw-image-alignment-options-popup{
      background:var(--sticky-note-bg-color);
      border:none;
    }
    .rdw-dropdown-optionwrapper{
      top:-280px;
    }
    .public-DraftStyleDefault-block{
      &:first-of-type{
        margin-top: 0;
      }
    }
  }*/
}

.youtube-widget{
  .common-text-form input {
    border-radius: 3px 0 0 3px;
  }
  .search-btn{
    width: 40px;
    border-radius: 0 3px 3px 0;
    background-color: #394ADD;
    svg {
      font-size: 20px;
    }
  }
  .youtube-body{
    height:100%;
    display: flex;
    flex-direction: column;
    color: #525f7f;
    background-color: #000000;
    .browser {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      .youtube-iframe {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        iframe {
          margin-top: 3%;
        }
      }
    }
  }
}
.react-flow__edge-path {
  stroke-width: 3;
  stroke: #6b6d72
}
.react-flow__edge{
  cursor:default;

}
.react-flow__handle{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 4px solid transparent;
  background: transparent;
  box-sizing: border-box;
  margin-top: 5px;
  &.target{
    //pointer-events: none;
    border-color:#66E666;
    background:transparent;
    margin-left: -4px;


    &:hover{
      //background:red;
      //opacity: 0.1;
    }
  }
  &.source{
    border-color: #29b6f2;
    background:transparent;
    margin-right: -4px;

    &:hover{
      //background:blue;
      //opacity: 0.1;
    }
  }
}

.react-flow__handle-bottom {
  top: 50%;
  transform: translate(-50%, 0);
}







